<template>
    <div id="main">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            option: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    right: 0,
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 13,
                    bottom: 20,
                    itemStyle: {
                        borderWidth :0
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize:9
                    }
                },
                series: [
                    {
                        name: '行业分类',
                        type: 'pie',
                        radius: ['45%', '70%'],
                        center: ['40%', '50%'],
                        color: ['#0185F4', '#F65245', '#2CC35E', '#5CC3F4', '#F7C754'],
                        // itemStyle: {
                        //     borderColor: 'transparent',
                        //     borderWidth: 2,
                        // },
                        label: {
                            show: true,
                            fontSize: 9,
                            fontFamily: "Courier New",
                            color: "#fff",
                            formatter: '{b}\n{d}%',
                            lineHeight: 15,
                            // alignTo:'edge',
                            // edgeDistance: '5%',
                            distanceToLabelLine: -5,
                            labelLine: {
                                show:true
                            }
                        },
                        
                        data: [
                            { value: 30, name: '工厂' },
                            { value: 20, name: '园区' },
                            { value: 10, name: '政府部门' },
                            { value: 50, name: '集团公司' },
                            { value: 40, name: '家政行业' }
                        ],
                    }
                ]
            }
        }
    },
    methods: {
        drawPie(id) {
            this.myChart = echarts.init(document.getElementById(id))
            this.myChart.setOption(this.option);
        }
    },
    mounted() {
        this.$nextTick(function () { //延迟函数调用  dom 数据更新后执行
            this.drawPie('main')
        });
    },
}

</script>

<style lang="less" scoped>
#main {
    width: 360px;
    height: 192px;
}
</style>