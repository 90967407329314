<template>
    <div class="login flex-center">
        <img src="../assets/img/loginBg.png" alt="" class="login-bg">
        <div class="login-box flex-c">
            <img src="../assets/img/login.png" alt="" class="box-bg">
            <div class="box-div1 flex-c-center">
                <span class="font-s-40 c-fff">圣康可视化数据大屏</span>
                <span class="font-s-22 c-fff m-t-18">SHENGKANG VISUALIZATION DATA SCREEN</span>
            </div>
            <div class="box-div flex flex-a-c flex-1">
                <img src="../assets/img/logo4.png" alt="" class="div-left">
                <div class="div-center"></div>
                <div class="div-right flex-1 flex-c flex-a-c">
                    <div class="account flex-c font-s-22 c-fff">
                        <div class="flex">
                            <span class=" right-span text-a-j ">用户名</span>
                            <input type="text" v-model="form.account" @blur="blur(0, form.account, '用户名')"
                                class="right-input m-l-30">
                        </div>
                        <div class="c-ed4014 right-br">{{ msgs[0] }}</div>
                    </div>
                    <div class="password flex-c font-s-22 c-fff">
                        <div class="flex">
                            <span class=" right-span text-a-j">密码</span>
                            <input type="password" v-model="form.password" @blur="blur(1, form.password, '密码')"
                                class="right-input m-l-30">
                        </div>
                        <div class="c-ed4014 right-br">{{ msgs[1] }}</div>
                    </div>
                    <button @click="login" class="right-btn pointer filter m-t-24">登录</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
const {proxy} = getCurrentInstance()

let form = ref({
    account: '',
    password: ''
})

let msgs = ref(['', ''])

let login = () => {
    proxy.$router.push({
        path:'/index/home'
    })
    proxy.$Message.success('登录成功')
}

let blur = (i, text, msg) => {
    if (!text) {
        msgs.value[i] = `${msg}不能为空`
    } else {
        msgs.value[i] = ''
    }
}


</script>

<style lang="less" scoped>
.login {
    width: 100vw;
    height: 100vh;

    .login-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
    }

    .login-box {
        width: 8.89rem;
        height: 5.2rem;
        border-radius: 4px;
        position: relative;

        .box-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .box-div1 {
            width: 100%;
            height: 1.73rem;
        }

        .box-div {
            width: 100%;

            .div-left {
                width: 2.98rem;
                height: 2.83rem;
                margin: 0 .3rem;
            }

            .div-center {
                width: .02rem;
                height: 2.67rem;
                background-image: linear-gradient(0deg,
                        #ffffff 0%,
                        #ffffff 49%,
                        rgba(255, 255, 255, 0.5) 74%,
                        #ffffff 100%);
                opacity: 0.55;
            }

            .div-right {

                .right-span {
                    width: .75rem;

                }

                .right-input {
                    width: 3.1rem;
                    height: 0.36rem;
                    background-color: rgba(0, 84, 160, 0.42);
                    box-shadow: 5px 8px 9px 0px rgba(15, 6, 14, 0.13),
                        inset 3px 5px 16px 0px rgba(15, 6, 14, 0.21);
                    border-radius: 4px;
                    border: solid 2px rgba(127, 191, 255, 0.26);
                    font-size: .22rem;
                    color: #fff;
                    outline: none;
                    padding-left: .1rem;
                }


                .right-br {
                    height: .43rem;
                    line-height: .43rem;
                    font-size: .18rem;
                    padding-left: 1.07rem;
                }
            }

            .right-btn {
                width: 1.25rem;
                height: .43rem;
                background-image: linear-gradient(0deg,
                        rgba(0, 150, 218, 0.68) 0%,
                        rgba(44, 208, 255, 0.68) 100%);
                box-shadow: 5px 8px 9px 0px rgba(15, 6, 14, 0.24);
                border-radius: 4px;
                border: solid 2px rgba(104, 180, 255, 0.36);
                font-size: .22rem;
                color: #ffffff;
                letter-spacing: .02rem;
            }
        }
    }
}
</style>