<template>
    <div id="categoty"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            option: {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
                    axisLabel: {
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#5B81C0",
                            fontSize: '8',
                        },
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#5B81C0",
                            fontSize: '8',
                        },
                    }
                },
                dataZoom: [
                    // {
                    //     type: 'inside',
                    //     start: 0,
                    //     end: 10
                    // },
                    {
                        start: 0,
                        end: 100
                    }
                ],
                tooltip: {
                    trigger: 'axis'
                },
                series: [
                    {
                        data: [100, 200, 220, 140, 300, 200, 400, 300, 200, 100, 500, 300, 400, 300, 100, 200, 220, 140, 300, 400],
                        type: 'line',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: '#194384' },
                                    { offset: 1, color: '#061232' }
                                ]
                            )
                        },
                        itemStyle: {
                            normal: {
                                color: '#fff', //改变折线点的颜色
                                lineStyle: {
                                    color: '#2569C3' //改变折线颜色
                                }
                            }
                        },
                    }
                ],
                grid: {
                    top: '10px',
                    left: '30px',
                    right: '30px',
                    bottom: '80px',
                }
            }
        }
    },
    methods: {
        drawPie(id) {
            this.myChart = echarts.init(document.getElementById(id))
            this.myChart.setOption(this.option);
        }
    },
    mounted() {
        this.$nextTick(function () { //延迟函数调用  dom 数据更新后执行
            this.drawPie('categoty')
        });
    },
}

</script>

<style lang="less" scoped>
#categoty {
    width: 595px;
    height: 190px;
}
</style>