<template>
    <div class="dissfiy flex-c">
        <img :src="src" alt="" class="dissfiy-img" />
        <div class="title flex-center" v-if="title">
            <span class="font-s-24 c-6fc3ff lettet-spacing-2" v-if="type !== 'warn'">{{  title  }}</span>
            <span class="font-s-24 c-e76e73 lettet-spacing-2" v-else>{{  title  }}</span>
        </div>

        <!-- 设备区域情况一览 -->
        <div class="flex1 p-0-27 detail sroll-y m-t-26" v-if="type === 'detail'">
            <div class="flex-c m-b-15" v-for="item, index in list" :key="index">
                <!-- 省 -->
                <div class="detail-div flex flex-a-c flex-j-b m-b-15 pointer" @click="fadeFalgs[index] = !fadeFalgs[index]">
                    <div class="div-progress">
                        <div class="progress-div" :style="{ width: item.plan + '%' }">
                            <div class="fill"></div>
                        </div>
                    </div>
                    <span class="font-s-18 c-fff pointer">{{  item.plan  }}</span>
                    <span class="font-s-18 c-fff pointer">{{  item.size  }}</span>
                </div>
                <!-- 市 -->
                <div class="flex-c" :class="[fadeFalgs[index] ? 'slide-out-top' : 'fade-in-top']" v-if="item.classify">
                    <div class="detail-div1 flex flex-a-c flex-j-b m-b-15">
                        <div class="div-progress1">
                            <div class="progress1-div" :style="{ width: item.classify[selectIndex[index]].plan + '%' }">
                                <div class="fill"></div>
                            </div>
                        </div>
                        <span class="font-s-14 c-fff">{{  item.classify[selectIndex[index]].plan  }}</span>
                        <select class="font-s-14 bg-t c-fff border-0 pointer" @change="xz($event, index)">
                            <option class="c-000" :value="index1" v-for="item1, index1 in item.classify"
                                :key="index + '-' + index1">{{  item1.size  }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- 行业分类 -->
        <div class="classify flex-1" v-else-if="type === 'classify'">
            <echarts></echarts>
        </div>

        <!-- 实时监测数据 -->
        <div class="data flex-1 flex flex-wrap" v-else-if="type === 'data'">
            <div class="data-div flex-center transform-180" v-for="item, index in list" :key="index">
                <Circle class="circle" :size="140" :trail-width="4" :stroke-width="8"
                    :percent="animation(index, item.percent)" stroke-linecap="round"
                    :stroke-color="['#2F7CF7', '#2FBAFB']" trail-color="#1A3E94">
                    <div class="flex-c-center transform-180">
                        <img :src="require('../assets/' + item.src)" alt="" class="div-img1">
                        <span class="font-s-24 c-6fc3ff" v-if="item.num">{{  item.num  }}</span>
                        <span class="font-s-24 c-6fc3ff" v-else>{{  item.percent  }}%</span>
                    </div>
                </Circle>
            </div>
            <div class="data-div flex-c flex-j-c flex-a-c">
                <div>
                    <div class="flex flex-a-c m-b-15" v-for="item, index in list" :key="index">
                        <img :src="require('../assets/' + item.src)" alt="" class="div-img">
                        <span class="font-s-16 c-fff">{{  item.title  }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- 实时监测数据2 -->
        <div class="data2 flex-1 flex-c flex-a-c" v-else-if="type === 'data2'">
            <div class="flex-1 data2-div1 flex flex-wrap">
                <div class="div1-div flex-center transform-180" v-for="item, index in list" :key="index">
                    <Circle class="circle" :size="110" :trail-width="4" :stroke-width="8"
                        :percent="animation(index, item.percent)" stroke-linecap="round"
                        :stroke-color="['#2F7CF7', '#2FBAFB']" trail-color="#1A3E94">
                        <div class="flex-c-center transform-180">
                            <img :src="require('../assets/' + item.src)" alt="" class="div1-img1">
                            <span class="font-s-24 c-6fc3ff" v-if="item.num">{{  item.num  }}</span>
                            <span class="font-s-24 c-6fc3ff" v-else>{{  item.percent  }}%</span>
                        </div>
                    </Circle>
                </div>
            </div>
            <div class="data2-div flex flex-wrap">
                <div class="flex div-div flex-a-c m-b-15" v-for="item, index in list" :key="index">
                    <img :src="require('../assets/' + item.src)" alt="" class="div-img1">
                    <span class="font-s-16 c-fff">{{  item.title  }}</span>
                </div>
            </div>
        </div>

        <!-- 信息提示 -->
        <div class="hint flex-c-center flex-1" v-else-if="type === 'hint'">
            <span class="font-s-26 c-fff m-b-20">您有一条信息需要处理</span>
            <span class="font-s-20 c-fff m-b-40">具体来源区域：广东省/广州市/天河区</span>
            <div class="flex flex-j-c ">
                <button class="hint-btn filter pointer">忽略</button>
                <router-link to="detail">
                    <button class="hint-btn1 filter pointer">查看详情</button>
                </router-link>
            </div>
        </div>

        <!-- 用水量时间区域图 -->
        <div class="chart flex-1 m-t-17 flex-c flex-a-c" v-else-if="type === 'chart'">
            <div class="chart-div">
                <category></category>
            </div>
        </div>

        <!-- 报警来源区域TOP5 -->
        <div class="TOP5 flex-1 m-t-10" v-else-if="type === 'TOP5'">
            <bar></bar>
        </div>

        <!-- 物联预警 -->
        <div class="early flex-1 m-t-16" v-else-if="type === 'early'">
            <div class="flex-c p-0-23">
                <div class="early-title flex flex-j-b m-b-13">
                    <div class="flex"><span class="font-s-12 c-fff">今日预警：</span><span
                            class="font-s-12 c-5b81c0">12</span></div>
                    <div class="flex"><span class="font-s-12 c-fff">待处理：</span><span class="font-s-12 c-5b81c0">5</span>
                    </div>
                    <div class="flex"><span class="font-s-12 c-fff">已处理：</span><span class="font-s-12 c-5b81c0">7</span>
                    </div>
                    <div class="flex"><span class="font-s-12 c-5b81c0">累积预警：</span><span
                            class="font-s-12 c-5b81c0">230</span></div>
                </div>
                <div class="flex-c early-div flex-j-b">
                    <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                            class="div-span text-a-j">时间</span><span>：2022-06-30
                            10:00:00</span></div>
                    <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                            class="div-span text-a-j">地点</span><span>：广州海珠区</span>
                    </div>
                    <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                            class="div-span text-a-j">报警类型</span><span>：设备报警</span>
                    </div>
                    <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                            class="div-span text-a-j">预警等级</span><span>：2级报警</span>
                    </div>
                    <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                            class="div-span text-a-j">状态</span><span>：待处置</span>
                    </div>
                    <div class="flex font-s-14 c-f83d42 letter-spacing-1">
                        <span>是否需要通知相关部门：</span><span>设备维修部/广州维修部</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- 警告 -->
        <div class="warn flex-1 m-t-32 flex-c flex-a-c flex-j-b" v-else-if="type === 'warn'">
            <span class="font-s-20 c-fff warn-span letter-spacing-2">广东省佛山市高明区云勇林场 19 单元 60 室</span>
            <span class="font-s-18 c-fff letter-spacing-2">设备出现异常，请及时处理！</span>
            <router-link to="exception">
                <button class="warn-btn1 filter pointer">查看详情</button>
            </router-link>
        </div>

        <!-- 实时监测数据3 -->
        <div class="data3 flex-1 m-t-23 flex-c p-0-11" v-else-if="type === 'data3'">
            <!-- 头部 -->
            <div class="flex flex-j-b m-b-20">
                <div class="data3-div font-s-16 c-89b2f7">
                    <div class="div-div text-a-c">正常/异常</div>
                </div>
                <div class="data3-div1 font-s-16 c-89b2f7 text-a-c">设备数</div>
                <div class="data3-div2 font-s-16 c-89b2f7 text-a-r">区</div>
            </div>
            <!-- 数据 -->
            <div class="data3-sroll sroll-y">
                <div class="data3-div4 flex flex-a-c flex-j-b m-b-15" v-for="item, index in list" :key="index">
                    <div class="data3-div flex flex-a-c">
                        <div class="div-progress">
                            <div class="progress-div" :style="{ width: (item.res / item.all) * 100 + '%' }">
                                <div class="fill"></div>
                            </div>
                        </div>
                        <span class="font-s-18 c-fff">{{  item.res + '/' + item.err  }}</span>
                    </div>
                    <div class="data3-div1 font-s-18 c-fff text-a-c">{{  item.all  }}</div>
                    <span class="font-s-18 c-fff">{{  item.size  }}</span>
                </div>
            </div>
        </div>

        <!-- 物联预警1 -->
        <div class="early2 flex-1 m-t-16" v-else-if="type === 'early2'">
            <div class="flex-c p-0-23">
                <div class="early2-title flex flex-j-b m-b-13">
                    <div class="flex"><span class="font-s-12 c-fff">今日预警：</span><span
                            class="font-s-12 c-5b81c0">12</span></div>
                    <div class="flex"><span class="font-s-12 c-fff">待处理：</span><span class="font-s-12 c-5b81c0">5</span>
                    </div>
                    <div class="flex"><span class="font-s-12 c-fff">已处理：</span><span class="font-s-12 c-5b81c0">7</span>
                    </div>
                    <div class="flex"><span class="font-s-12 c-5b81c0">累积预警：</span><span
                            class="font-s-12 c-5b81c0">230</span></div>
                </div>
                <div class="early2-box sroll-y flex-c">
                    <div class="flex-c early2-div flex-j-b m-b-15" v-for="item, index in list" :key="index">
                        <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                                class="div-span text-a-j">时间</span><span>：{{  item.time  }}</span></div>
                        <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                                class="div-span text-a-j">地点</span><span>：{{  item.site  }}</span>
                        </div>
                        <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                                class="div-span text-a-j">报警类型</span><span>：{{  item.type  }}</span>
                        </div>
                        <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                                class="div-span text-a-j">预警等级</span><span>：{{  item.grade  }}</span>
                        </div>
                        <div class="flex font-s-14 c-f83d42 letter-spacing-1"><span
                                class="div-span text-a-j">状态</span><span>：{{  item.state  }}</span>
                        </div>
                        <div class="flex font-s-14 c-f83d42 letter-spacing-1">
                            <span>是否需要通知相关部门：</span><span>{{  item.note  }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 紧急事件 -->
        <div class="warn2 flex-1 flex-c p-0-7 m-t-17" v-else-if="type === 'warn2'">
            <span class="font-s-14 c-fff lettet-spacing-1 m-b-6">标题：{{  list.title  }}</span>
            <span class="font-s-14 c-fff lettet-spacing-1 m-b-13">时间：{{  list.time  }}</span>
            <span class="font-s-12 c-fff lettet-spacing-1 m-b-7 text-overflow-2">{{  list.text  }}</span>
            <span class="font-s-12 c-fff lettet-spacing-1 text-overflow-3">{{  list.text1  }}</span>
        </div>

        <!-- 案卷图标 -->
        <div class="chart2 flex-1 flex-c p-0-20" v-else-if="type === 'chart2'">
            <div class="chart2-div m-t-16 flex flex-a-c">
                <img src="../assets/img/serch.png" alt="" class="div-img">
                <input type="text" class="flex-1 div-input c-fff" placeholder="搜索项目名称">
            </div>
            <div class="flex m-t-18 m-b-2">
                <div class="flex m-r-24"><span class="font-s-12 c-fff">今日新增案卷数：</span><span
                        class="font-s-12 c-68bbc4">25</span></div>
                <div class="flex m-r-24"><span class="font-s-12 c-fff">昨日新增案卷数：</span><span
                        class="font-s-12 c-68bbc4">55</span></div>
                <div class="flex"><span class="font-s-12 c-fff">现存案卷数: </span><span
                        class="font-s-12 c-68bbc4">120</span></div>
            </div>
            <div>
                <category2></category2>
            </div>
        </div>

        <!-- 首页地图 -->
        <div class="size flex-1" v-else-if="type === 'size'">
            <!-- 进度环 -->
            <div class="size-div flex flex-j-b">
                <div class="div-div flex-c flex-a-c" v-for="item, index in list" :key="index">
                    <Circle :percent="animation(index, item.percent)" dashboard :size="70" :stroke-width="10"
                        :trail-width="10" :stroke-color="['#01B0DF', '#135CA9']" trail-color="#182962">
                        <span class="demo-circle-inner c-5b81c0" style="font-size:12px">{{  item.num  }}</span>
                    </Circle>
                    <span class="font-s-14 c-fff">{{  item.title  }}</span>
                </div>
            </div>
            <!-- 中国地图 -->
            <china></china>
        </div>

        <!-- 警报地图 -->
        <div class="size1 flex-1" v-else-if="type === 'size1'">
            <!-- 进度环 -->
            <div class="size1-div flex flex-j-b">
                <div class="div-div flex-c flex-a-c" v-for="item, index in list" :key="index">
                    <Circle :percent="animation(index, item.percent)" dashboard :size="70" :stroke-width="10"
                        :trail-width="10" :stroke-color="['#01B0DF', '#135CA9']" trail-color="#182962">
                        <span class="demo-circle-inner c-5b81c0" style="font-size:12px">{{  item.num  }}</span>
                    </Circle>
                    <span class="font-s-14 c-fff">{{  item.title  }}</span>
                </div>
            </div>
            <!-- 中国地图 -->
            <china1></china1>
        </div>

        <!-- 弹出框 -->
        <!-- <Modal v-model="detailModel" title="提示" @on-ok="detailOk" @on-cancel="detailNo">
            <p>是否进入查看详情</p>
        </Modal> -->
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import echarts from "./echarts.vue";
import category from "./category.vue";
import category2 from "./category2.vue";
import bar from "./bar.vue";
import china from "./size.vue";
import china1 from "./size1.vue";
const props = defineProps({
    src: String,
    title: String,
    type: String
})
const { proxy } = getCurrentInstance()
let list = ref('')
let fadeFalgs = ref([])
const getList = () => {
    if (props.type === 'detail') {
        // 设备区域情况一览
        list.value = [
            { size: '广东省', plan: 40, classify: [{ size: '广州市', plan: 40 }, { size: '深圳市', plan: 15 }, { size: '佛山市', plan: 75 }, { size: '东莞市', plan: 40 }, { size: '中山市', plan: 15 }, { size: '珠海市', plan: 75 }] },
            { size: '福建省', plan: 15, classify: [{ size: '福州市', plan: 40 }, { size: '莆田市', plan: 15 }, { size: '泉州市', plan: 75 }] },
            { size: '河北省', plan: 75 },
            { size: '辽宁省', plan: 30 },
            { size: '吉林省', plan: 20 },
            { size: '江苏省', plan: 25 },
            { size: '浙江省', plan: 35 },
            { size: '湖南省', plan: 45 },
            { size: '四川省', plan: 50 },
            { size: '内蒙古', plan: 55 },
            { size: '湖北省', plan: 60 },
            { size: '河南省', plan: 65 },
            { size: '广西省', plan: 70 },
        ]
        for (let i in list.value) {
            fadeFalgs.value.push(false)
            selectIndex.value.push(0)
        }
    } else if (props.type === 'data') {
        // 实时监测数据
        list.value = [
            { all: 15000, num: '12,245', src: 'img/logo.png', title: '净水器总数', percent: 60 },
            { all: 15000, num: '12,245', src: 'img/logo1.png', title: '当前正常运行总数', percent: 70 },
            { src: 'img/logo2.png', title: '今日活跃率', percent: 60 }
        ]
    } else if (props.type === 'data2') {
        // 实时监测数据2
        list.value = [
            { all: 15000, num: '12,245', src: 'img/logo.png', title: '净水器总数', percent: 60 },
            { all: 15000, num: '12,245', src: 'img/logo1.png', title: '当前正常运行总数', percent: 70 },
            { src: 'img/logo2.png', title: '今日活跃率', percent: 60 },
            { all: 15000, num: '10,317', src: 'img/logo3.png', title: '当前异常', percent: 40 }
        ]
    } else if (props.type === 'data3') {
        // 实时监测数据3
        list.value = [
            { size: '天河区', all: 40, res: 30, err: 10 },
            { size: '越秀区', all: 15, res: 10, err: 5 },
            { size: '荔湾区', all: 75, res: 50, err: 25 },
            { size: '番禺区', all: 30, res: 20, err: 10 },
            { size: '南沙区', all: 20, res: 10, err: 10 },
            { size: '白云区', all: 25, res: 15, err: 10 },
            { size: '花都区', all: 35, res: 25, err: 10 },
            { size: '增城区', all: 45, res: 35, err: 10 },
            { size: '海珠区', all: 50, res: 30, err: 20 },
            { size: '黄浦区', all: 55, res: 30, err: 25 },
            { size: '从化区', all: 60, res: 50, err: 10 },
            { size: '天河区', all: 65, res: 55, err: 10 },
            { size: '越秀区', all: 70, res: 40, err: 30 },
            { size: '天河区', all: 40, res: 30, err: 10 },
            { size: '越秀区', all: 15, res: 10, err: 5 },
            { size: '荔湾区', all: 75, res: 50, err: 25 },
            { size: '番禺区', all: 30, res: 20, err: 10 },
            { size: '南沙区', all: 20, res: 10, err: 10 },
            { size: '白云区', all: 25, res: 15, err: 10 },
        ]
    } else if (props.type === 'early2') {
        // 物联预警1
        list.value = [
            { time: '2022-06-30 10:00:00', siti: '广州海珠区', type: '设备报警', grade: '2级报警', state: '待处置', note: '设备维修部/广州维修部' },
            { time: '2022-06-30 10:00:00', siti: '广州海珠区', type: '设备报警', grade: '2级报警', state: '待处置', note: '设备维修部/广州维修部' },
            { time: '2022-06-30 10:00:00', siti: '广州海珠区', type: '设备报警', grade: '2级报警', state: '待处置', note: '设备维修部/广州维修部' },
        ]
    } else if (props.type === 'warn2') {
        // 紧急事件
        list.value = {
            title: '广州市天河区台风季防汛预警通知公告',
            time: '2022-06-30 10:00:00',
            text: '受低涡低槽影响,6月30日-7月1日我区出现短时大风,局地强降水等强对流天气,此次过程南部及北部局地降水较大,全区平均降水量37.6毫米,副中心核心区平均降水量32.9毫米。',
            text1: '为做好本轮降雨应对工作区防止及相关部门密切配合，狠抓落实，成功应对本次降雨过程。降雨期间，全区主要道路、立交桥及重点部位未出现积水现象，城市道路运行通畅，各主要喝到水位运行平稳，行洪闸坝等水工建筑物运行正常，未发生任何灾情、险情。'
        }
    } else if (props.type === 'size') {
        // 首页地图
        list.value = [
            { title: '实时正常运行设备', num: '12,245', percent: 80 },
            { title: '总储水量(吨)', num: '5,435,9', percent: 50 },
            { title: '总储水量(吨)', num: '4,363,7', percent: 70 },
            { title: 'TDS值', num: '1,367', percent: 60 },
        ]
    } else if (props.type === 'size1') {
        // 警报地图
        list.value = [
            { title: '实时正常运行设备', num: '12,245', percent: 80 },
            { title: '总储水量(吨)', num: '5,435,9', percent: 50 },
            { title: '总储水量(吨)', num: '4,363,7', percent: 70 },
            { title: 'TDS值', num: '1,367', percent: 60 },
        ]
    }
}

onMounted(() => {
    getList()
})
// 是否查看详情
// let i = ref(0)
// let detailModel = ref(false)
// const upDetail = (index) => {
//     if (fadeFalgs.value[index]) {
//         detailModel.value = true
//         i.value = index
//     } else {
//         fadeFalgs.value[index] = !fadeFalgs.value[index]
//     }
// }
// const detailOk = () => {
    
// }
// const detailNo = () => {
//     fadeFalgs.value[i] = !fadeFalgs.value[i]
// }
// 数字动画
let keyList = ref([0, 0, 0, 0])
let animation = (index, percent) => {
    let time = setInterval(() => {
        if (keyList.value[index] < percent) {
            keyList.value[index]++
        } else {
            clearInterval(time)
        }
    })
    return keyList.value[index]
}

// 收缩动画


// 下拉选项
let selectIndex = ref([])
const xz = (e, i) => {
    selectIndex.value[i] = e.target.value
}
</script>

<style lang="less" scoped>
.dissfiy {
    width: 100%;
    height: 100%;
    position: relative;

    .dissfiy-img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .title {
        width: 100%;
        height: .465rem;
    }

    // 设备区域情况一览
    .detail {
        height: 4rem;

        .detail-div {
            width: 100%;
            height: .26rem;

            .div-progress {
                width: 2.64rem;
                height: .08rem;
                background: #19507A;
                border-radius: .29rem;

                .progress-div {
                    border-radius: .29rem;
                    height: 100%;
                    background: #01B0DF;
                    animation: progress 2s;
                    position: relative;

                    @keyframes progress {
                        0% {
                            width: 0;
                        }
                    }


                }
            }
        }

        .detail-div1 {
            width: 3.7rem;
            height: .25rem;

            .div-progress1 {
                width: 2.47rem;
                height: .06rem;
                background: #19507A;
                border-radius: .29rem;

                .progress1-div {
                    border-radius: .29rem;
                    height: 100%;
                    background: #FFDF8E;
                    animation: progress 2s;
                    position: relative;

                    @keyframes progress {
                        0% {
                            width: 0;
                        }
                    }


                }
            }
        }

        .detail-div1:last-child {
            margin-bottom: 0;
        }

        .fill {
            border-radius: .29rem;
            animation: move 2s infinite;
            height: 100%;
            position: absolute;
        }

        @keyframes move {
            0% {
                background-color: rgba(255, 255, 255, .0);
                width: 0;
            }

            50% {
                background-color: rgba(255, 255, 255, .5)
            }

            100% {
                background-color: rgba(255, 255, 255, .0);
                width: 100%;
            }
        }
    }

    // 实时监测数据
    .data {
        .data-div {
            width: 50%;
            height: 50%;

            .div-img {
                width: .25rem;
                height: .25rem;
                margin-right: .11rem;
            }

            .div-img1 {
                width: .4rem;
                height: .4rem;
                margin-bottom: .1rem;
            }
        }
    }

    // 信息提示
    .hint {
        .hint-btn {
            width: 1.2rem;
            height: .46rem;
            background: #D9D9D9;
            border-radius: 10px;
            font-size: .2rem;
            color: #333;
            margin-right: .72rem;
        }

        .hint-btn1 {
            width: 1.2rem;
            height: .46rem;
            background: #2569C3;
            border-radius: 10px;
            font-size: .2rem;
            color: #fff;
        }
    }

    // 用水量
    .chart {

        .chart-div {
            width: 7.11rem;
            height: 1.3rem;
        }
    }

    // 实时监测数据2
    .data2 {

        .data2-div {
            width: 3.53rem;

            .div-div {
                width: 50%;

                .div-img1 {
                    width: .25rem;
                    height: .25rem;
                    margin-right: .11rem;
                }
            }
        }

        .data2-div1 {
            width: 100%;

            .div1-div {
                width: 50%;
                height: 50%;
            }

            .div1-img1 {
                width: .4rem;
                height: .4rem;
                margin-bottom: .1rem;
            }
        }
    }

    // 物联预警
    .early {
        .early-div {
            height: 1.63rem;
            padding: .1rem .2rem;
            background-color: #0A1B46;
            opacity: 0.77;
        }

        .div-span {
            width: .8rem;
            display: inline-block;
        }
    }

    // 警告
    .warn {

        .warn-span {
            width: 3.34rem;
            text-align: center;
        }

        .warn-btn1 {
            width: 1.2rem;
            height: .46rem;
            background: #0186E5;
            border-radius: 10px;
            font-size: .2rem;
            color: #fff;
            margin-bottom: .2rem;
        }
    }

    // 实时监测数据3
    .data3 {

        .data3-div {
            width: 2.66rem;

            .div-div {
                width: 2.07rem;
            }
        }

        .data3-div1 {
            width: .6rem;
        }

        .data3-div2 {
            width: .6rem;
        }

        .data3-sroll {
            height: 7.15rem;
        }

        .data3-div4 {
            width: 100%;
            height: .26rem;

            .div-progress {
                width: 2.07rem;
                height: .08rem;
                background: #19507A;
                border-radius: .29rem;
                margin-right: .1rem;

                .progress-div {
                    border-radius: .29rem;
                    height: 100%;
                    background: #01B0DF;
                    animation: progress 2s;
                    position: relative;

                    @keyframes progress {
                        0% {
                            width: 0;
                        }
                    }

                    .fill {
                        border-radius: .29rem;
                        animation: move 2s infinite;
                        // background-image: linear-gradient(to right,rgba(255,255,255,.2),rgba(255,255,255,.8));
                        height: 100%;
                        position: absolute;
                    }

                    @keyframes move {
                        0% {
                            background-color: rgba(255, 255, 255, .0);
                            width: 0;
                        }

                        50% {
                            background-color: rgba(255, 255, 255, .5)
                        }

                        100% {
                            background-color: rgba(255, 255, 255, .0);
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    // 物联预警2
    .early2 {
        .early2-div {
            height: 1.63rem;
            padding: .1rem .2rem;
            background-color: #172551;
            opacity: 0.77;
        }

        .early2-box {
            height: 4.47rem;
        }

        .div-span {
            width: .8rem;
            display: inline-block;
        }
    }

    // 案卷
    .chart2 {

        .chart2-div {
            width: 100%;
            height: .38rem;
            background-color: rgba(24, 63, 136, 0.7);
            border-radius: .7rem;
            opacity: 0.8;

            .div-img {
                width: .24rem;
                height: .24rem;
                margin: 0 .16rem 0 .08rem;
            }

            .div-input {
                background: none;
                outline: none;
                border: none;
            }
        }
    }

    // 首页地图
    .size {
        position: relative;

        .size-div {
            width: 5.41rem;
            position: absolute;
            top: .2rem;
            left: 50%;
            margin-left: -2.7rem;

            .div-div {
                position: relative;
                z-index: 999;
            }
        }
    }

    // 警报地图
    .size1 {
        position: relative;

        .size1-div {
            width: 6.16rem;
            position: absolute;
            top: .2rem;
            left: 50%;
            margin-left: -3.08rem;

            .div-div {
                position: relative;
                z-index: 999;
            }
        }
    }
}
</style>