import { createRouter, createWebHashHistory } from 'vue-router'
import index from '../views/index.vue'
import home from '../views/home.vue'
import detail from '../views/detail.vue'
import exception from '../views/exception.vue'
import login from '../views/login.vue'

const routes = [
  {
    path: '/',
    component: login,
  },
  {
    path: '/login',
    component: login,
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    redirect:'/index/home',
    children: [
      {
        path: '/index/home',
        name:'home',
        component:home,
      },
      {
        path: '/index/detail',
        name:'detail',
        component:detail,
      },
      {
        path: '/index/exception',
        name:'exception',
        component:exception,
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
