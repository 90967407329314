<template>
    <div id="size">
    </div>
</template>

<script>
import * as echarts from 'echarts';
// import '../assets/js/china.js'
import geoJson from '../assets/js/china.json'
echarts.registerMap('china', geoJson)
export default {
    data() {
        return {
            option: {
                tooltip: {
                    trigger: 'item'
                },
                visualMap: {//颜色的设置  dataRange
                    x: 'left',
                    y: 'center',
                    splitList: [
                        { start: 1500 },
                        { end: 1500 }
                    ],
                    textStyle: {
                        color: '#fff',
                    },
                    min: 0,
                    max: 2000,
                    calculable: true,//颜色呈条状
                    // text: ['高', '低'],// 文本，默认为数值文本
                    color: ['#0F1F53', '#061340']
                },
                // toolbox: {//工具栏
                //     show: true,
                //     orient: 'vertical',//工具栏 icon 的布局朝向
                //     x: 'right',
                //     y: 'center',
                //     feature: {//各工具配置项。
                //         mark: { show: true },
                //         dataView: { show: true, readOnly: false },//数据视图工具，可以展现当前图表所用的数据，编辑后可以动态更新。
                //         restore: { show: true },//配置项还原。
                //         saveAsImage: { show: true }//保存为图片。
                //     }
                // },
                series: [
                    {
                        name: '设备区域情况',
                        type: 'map',
                        mapType: 'china',
                        roam: false,//是否开启鼠标缩放和平移漫游
                        itemStyle: {//地图区域的多边形 图形样式
                            normal: {//是图形在默认状态下的样式
                                label: {
                                    show: true,//是否显示标签
                                    textStyle: {
                                        color: "#fff",
                                        fontSize: 8,
                                    }
                                },
                                borderColor: '#6FC3FF',

                            },
                            emphasis: {//是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
                                label: {
                                    show: true,//选中状态是否显示省份名称
                                    color: '#fff'
                                },
                                areaColor: '#ed4014',
                            }
                        },
                        select: {
                            itemStyle: {
                                color: '#ed4014'
                            },
                        },
                        top: "18%",//组件距离容器的距离
                        // zoom:0.8,
                        data: [
                            { name: '北京', value: Math.round(Math.random() * 2000) },
                            { name: '天津', value: Math.round(Math.random() * 2000) },
                            { name: '上海', value: Math.round(Math.random() * 2000) },
                            { name: '重庆', value: Math.round(Math.random() * 2000) },
                            { name: '河北', value: Math.round(Math.random() * 2000) },
                            { name: '河南', value: Math.round(Math.random() * 2000) },
                            { name: '云南', value: Math.round(Math.random() * 2000) },
                            { name: '辽宁', value: Math.round(Math.random() * 2000) },
                            { name: '黑龙江', value: Math.round(Math.random() * 2000) },
                            { name: '湖南', value: Math.round(Math.random() * 2000) },
                            { name: '安徽', value: Math.round(Math.random() * 2000) },
                            { name: '山东', value: Math.round(Math.random() * 2000) },
                            { name: '新疆', value: Math.round(Math.random() * 2000) },
                            { name: '江苏', value: Math.round(Math.random() * 2000) },
                            { name: '浙江', value: Math.round(Math.random() * 2000) },
                            { name: '江西', value: Math.round(Math.random() * 2000) },
                            { name: '湖北', value: Math.round(Math.random() * 2000) },
                            { name: '广西', value: Math.round(Math.random() * 2000) },
                            { name: '甘肃', value: Math.round(Math.random() * 2000) },
                            { name: '山西', value: Math.round(Math.random() * 2000) },
                            { name: '内蒙古', value: Math.round(Math.random() * 2000) },
                            { name: '陕西', value: Math.round(Math.random() * 2000) },
                            { name: '吉林', value: Math.round(Math.random() * 2000) },
                            { name: '福建', value: Math.round(Math.random() * 2000) },
                            { name: '贵州', value: Math.round(Math.random() * 2000) },
                            { name: '广东', value: Math.round(Math.random() * 2000), selected: true },
                            { name: '青海', value: Math.round(Math.random() * 2000) },
                            { name: '西藏', value: Math.round(Math.random() * 2000) },
                            { name: '四川', value: Math.round(Math.random() * 2000) },
                            { name: '宁夏', value: Math.round(Math.random() * 2000) },
                            { name: '海南', value: Math.round(Math.random() * 2000) },
                            { name: '台湾', value: Math.round(Math.random() * 2000) },
                            { name: '香港', value: Math.round(Math.random() * 2000) },
                            { name: '澳门', value: Math.round(Math.random() * 2000) },
                        ]
                    }
                ]
            }
        }
    },
    methods: {
        drawPie(id) {
            this.myChart = echarts.init(document.getElementById(id))
            this.myChart.setOption(this.option);
        },
    },
    mounted() {
        this.$nextTick(function () { //延迟函数调用  dom 数据更新后执行
            this.drawPie('size')
        });
    },
}

</script>

<style lang="less" scoped>
#size {
    width: 678px;
    height: 400px;
}
</style>