<template>
    <div id="categoty2"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            option: {
                legend: { 
                    data: ['处理案卷数', '新增案卷数'],
                    right: 0,
                    bottom:0,
                    orient: 'vertical',
                    textStyle: {
                        color: '#fff',
                        fontSize:9
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    axisLabel: {
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#5B81C0",
                            fontSize: '8',
                        },
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#5B81C0",
                            fontSize: '8',
                        },
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                series: [
                    {
                        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        name: '处理案卷数',
                        type: 'line',
                    },
                    {
                        data: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
                        name: '新增案卷数',
                        type: 'line',
                    },
                ],
                grid: {
                    top: '10px',
                    left: '20px',
                    right: '18%',
                    bottom: '20px',
                }
            }
        }
    },
    methods: {
        drawPie(id) {
            this.myChart = echarts.init(document.getElementById(id))
            this.myChart.setOption(this.option);
        }
    },
    mounted() {
        this.$nextTick(function () { //延迟函数调用  dom 数据更新后执行
            this.drawPie('categoty2')
        });
    },
}

</script>

<style lang="less" scoped>
#categoty2 {
    width: 650px;
    height: 130px;
}
</style>