<template>
  <div class="home">
    <!-- 背景图 -->
    <img src="../assets/img/bg.png" alt="" class="home-img">
    <div class="box p-0-46 flex-c">
      <!-- 头部 -->
      <div class="box-div flex flex-j-b flex-a-c">
        <img src="../assets/img/top.png" alt="" class="box-img" />
        <!-- 左侧时间 -->
        <div class="left flex-center">
          <img src="../assets/img/Frame.png" alt="" class="left-img">
          <span class="left-span letter-spacing-1">2022-05-31 12:35</span>
        </div>
        <!-- 中间标题 -->
        <div class="center flex-c flex-a-c m-t-16">
          <span class="font-s-36 c-fff m-b-8 letter-spacing-2">圣康可视化数据大屏</span>
          <span class="font-s-18 c-fff leter-spacing-2">SHENGKANG VISUAIIZATION DATA SCREEN</span>
        </div>
        <!-- 右侧操作 -->
        <div class="right flex flex-a-c flex-j-end">
          <!-- 头像 -->
          <div class="right-tx flex">
            <img src="../assets/img/tx.png" alt="" class="tx-img pointer">
            <span class="tx-span letter-spacing-1 pointer border">姜小凯</span>
          </div>
          <img src="../assets/img/sz.png" alt="" class="right-img pointer" @click="modal1 = true">
          <img src="../assets/img/tc.png" alt="" class="right-img1 pointer" @click="modal = true">
        </div>
      </div>
      <!-- 内容 -->
      <router-view></router-view>
      <!-- 提示框 -->
      <Modal v-model="modal" title="提示" @on-ok="ok" class-name="vertical-center-modal">
        <span>是否退出登录</span>
      </Modal>
      <Modal v-model="modal1" title="个人中心" @on-ok="add" @on-cancel="cancel">
        <div class="font-s-18 m-b-20">修改密码</div>
        <div class="flex-c">
          <div class="flex font-s-16 flex-a-c m-b-13"><span class="modal-span text-a-r m-r-8">旧密码:</span><Input v-model="oldPassword" placeholder="请输入旧密码" style="width: 300px" size="small"/></div>
          <div class="flex font-s-16 flex-a-c m-b-13"><span class="modal-span text-a-r m-r-8">新密码:</span><Input v-model="newPassword" placeholder="请输入新密码" style="width: 300px" size="small"/></div>
          <div class="flex font-s-16 flex-a-c m-b-13"><span class="modal-span text-a-r m-r-8">确认新密码:</span><Input v-model="newPassword1" placeholder="请再次输入新密码" style="width: 300px" size="small"/></div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()


const ok = () => {
  proxy.$router.push({ path: '/login' })
  proxy.$Message.success('退出成功');
}

const add = () => {
  proxy.$router.push({ path: '/login' })
  proxy.$Message.success('修改成功');
}

let modal = ref(false)
let modal1 = ref(false)

let newPassword = ref('')
let oldPassword = ref('')
let newPassword1 =ref('')
</script>

<style lang="less">
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}

.home {
  max-width: 19.2rem;
  height: 10.8rem;
  position: relative;

  .home-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    background-color: #01071C;
  }

  .box {
    .box-div {
      height: 1.03rem;
      position: relative;
      margin: .32rem 0 .77rem;
      padding: 0 .28rem;

      .box-img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      .left {
        width: 3.87rem;
        height: .64rem;
        position: relative;
        margin-bottom: .15rem;

        .left-img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .left-span {
          font-size: .22rem;
          color: #FFFFFF;
        }
      }

      .right {
        width: 3.87rem;
        height: .6rem;

        .right-tx {
          margin-right: .4rem;

          .tx-img {
            width: .28rem;
            height: .28rem;
            margin-right: .08rem;
          }

          .tx-span {
            font-size: .22rem;
            color: #FFFFFF;
          }
        }

        .right-img {
          width: .28rem;
          height: .28rem;
          margin-right: .24rem;
        }

        .right-img1 {
          width: .28rem;
          height: .28rem;
        }
      }
    }
  }

  
}
.modal-span{
    min-width: 0.9rem;
}


</style>

