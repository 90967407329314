<template>
    <div id="bar"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['海珠', '白云', '番禺', '天河', '南沙'],
                    axisLabel: {
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#5B81C0",
                            fontSize: '9',
                        },
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            show: true,
                            fontFamily: '微软雅黑',
                            color: "#5B81C0",
                            fontSize: '9',
                        },
                    }
                },
                series: [
                    {
                        data: [5, 21, 13, 22, 12],
                        type: 'bar',
                        barWidth: '25%',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#6BCEFB' },
                                { offset: 1, color: '#0488E6' }
                            ])
                        },
                        realtimeSort: true,
                    }
                ],
                grid: {
                    top: '10px',
                    left: '30px',
                    right: '30px',
                    bottom: '30px',
                }
            }
        }
    },
    methods: {
        drawPie(id) {
            this.myChart = echarts.init(document.getElementById(id))
            this.myChart.setOption(this.option);
        }
    },
    mounted() {
        this.$nextTick(function () { //延迟函数调用  dom 数据更新后执行
            this.drawPie('bar')
        });
    },
}

</script>

<style lang="less" scoped>
#bar {
    width: 360px;
    height: 170px;
}
</style>